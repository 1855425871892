<template>
  <div v-editable="blok">
    <div
      :class="{
        'flex justify-end': columnIndex === 1,
      }"
      class="c-block-gallery -translate-x-5 transform lg:translate-x-0"
    >
      <div
        :class="{
          'lg:rounded-r-lg': isFluidContainer && columnIndex === 0,
          'lg:rounded-l-lg': isFluidContainer && columnIndex === 1,
          'lg:rounded-lg': !isFluidContainer,
          'lg:max-w-[60vw]': isFluidContainer,
        }"
        class="relative aspect-video size-full overflow-hidden"
      >
        <Swiper
          :modules="[A11y, Autoplay]"
          :slides-per-view="1"
          :loop="true"
          :keyboard="true"
          :autoplay="autoplayOptions"
          :observer="true"
          :observe-parents="true"
          :update-on-window-resize="true"
          class="h-full"
          @swiper="onSwiper"
        >
          <SwiperSlide
            v-for="image in blok.galleryItems"
            :key="image.id"
            @mouseenter="onSlideMouseenter"
            @mouseleave="onSlideMouseleave"
          >
            <figure
              class="flex size-full items-center justify-center overflow-hidden"
            >
              <NuxtImg
                :src="image.filename"
                :alt="image.alt"
                width="1300"
                class="size-full object-cover"
              />
            </figure>
          </SwiperSlide>
        </Swiper>

        <div
          v-if="showControls"
          :class="{
            'justify-end': !blok.showFullscreenControls,
          }"
          class="absolute bottom-0 right-0 z-10 flex w-full gap-3 p-3"
        >
          <div v-if="blok.showFullscreenControls" class="flex-grow">
            <UiMediaControl
              label="Fullscreen"
              icon="fullscreen"
              size="sm"
              @click="onFullscreen"
            />
          </div>

          <template v-if="blok.showControls">
            <UiMediaControl
              label="Previous"
              icon="arrow-left"
              size="sm"
              @click="onPrevious"
            />

            <UiMediaControl
              label="Next"
              icon="arrow-right"
              size="sm"
              @click="onNext"
            />
          </template>
        </div>
      </div>
    </div>

    <PhotoGallery
      v-if="blok.showFullscreenControls"
      :is-open="isPhotoGalleryVisible"
      :images="blok.galleryItems"
      @close="onModalClose"
    >
      <template #title>
        {{ blok.title }}
      </template>

      <template #subTitle>
        {{ blok.subTitle }}
      </template>
    </PhotoGallery>
  </div>
</template>

<script setup lang="ts">
import type { Swiper } from 'swiper'
import { A11y, Autoplay } from 'swiper/modules'
import type { BlockGalleryStoryblok } from '@/types/storyblok'

const props = defineProps<{
  blok: BlockGalleryStoryblok
  columnIndex?: number
  isFluidContainer?: boolean
}>()
const isPhotoGalleryVisible = ref<boolean>(false)
const autoplayOptions = computed(() =>
  props.blok.autoplay ? { delay: 5000 } : false,
)
const swiperInstance = ref<Swiper | null>(null)
const showControls = computed(
  () =>
    (props.blok.showControls || props.blok.showFullscreenControls) &&
    props.blok.galleryItems.length > 1,
)

function onSwiper(swiper: Swiper): void {
  swiperInstance.value = swiper
}

function onSlideMouseenter(): void {
  swiperInstance.value?.autoplay?.stop()
}

function onSlideMouseleave(): void {
  swiperInstance.value?.autoplay?.start()
}

function onFullscreen(): void {
  isPhotoGalleryVisible.value = true
}

function onPrevious(): void {
  swiperInstance.value?.slidePrev()
}

function onNext(): void {
  swiperInstance.value?.slideNext()
}

function onModalClose(): void {
  isPhotoGalleryVisible.value = false
}
</script>

<style lang="postcss">
.c-block-gallery {
  width: calc(100% + theme('spacing.10'));

  @screen lg {
    @apply w-full;
    max-width: calc(65vw + 1rem);
  }

  .swiper-wrapper {
    max-width: 100vw;
  }
}
</style>
